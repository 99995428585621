/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const IbarraRealNova_400Regular = require('./IbarraRealNova_400Regular.ttf');
export const IbarraRealNova_500Medium = require('./IbarraRealNova_500Medium.ttf');
export const IbarraRealNova_600SemiBold = require('./IbarraRealNova_600SemiBold.ttf');
export const IbarraRealNova_700Bold = require('./IbarraRealNova_700Bold.ttf');
export const IbarraRealNova_400Regular_Italic = require('./IbarraRealNova_400Regular_Italic.ttf');
export const IbarraRealNova_500Medium_Italic = require('./IbarraRealNova_500Medium_Italic.ttf');
export const IbarraRealNova_600SemiBold_Italic = require('./IbarraRealNova_600SemiBold_Italic.ttf');
export const IbarraRealNova_700Bold_Italic = require('./IbarraRealNova_700Bold_Italic.ttf');
